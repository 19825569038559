.home {
    .topImageContainer {
        position: relative;    
        overflow: hidden;    

        img {
            position: absolute;
            margin-top: -5%;
            left: 0;
            top: 0;
            width:100%;
        }
    }

    .overlayHeader {
        position: absolute;
        top: 30%;
        left: 5%;

        p {
            color:white;
            font-size: 4vw;
            font-weight: 500;
        }
    }

    .attestOverview {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 95%;
    }

    .footerList {
        ul {
            margin: 1em
        }

        li {
            list-style-type: none;
        }

        a {
            color: white;

            &:hover {
                color: red;
            }
        }
    }
}