.warningIcon {
    width: 30px;
    height: 30px;
    margin-bottom: 3px;
}

.placeholder {
    visibility: hidden;
    width: 1px;
    height: 30px;
    margin-bottom: 3px;
}