.rangeSelect {
    width: 350px;
    margin-right: auto;
}

.warningMessage {
    color: red
}

.tenantFoundMessage {
    font-weight: bold;
    font-size: 110%;
}

.alignmentFix {
    input {
        padding-left: 30px;
        padding-top: 4px;
    }
    svg {
        margin-top: 2px;        
    }    
}