.auditTable {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    
    table {
        width: 100%;
    }

    tbody th {
        font-weight: 400;        
    }

    ul {
        list-style: circle;
        padding: 0px 20px;
    }
}

.center {
    display: flex;
    justify-content: center;
}