html {
    font-size: 14px;
  }
  
@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

:global(.mark) {
    background-color: red;
}