.mobileRecorderTable {
    width: 75%;
    overflow-x: auto;   
    
    table {
        width: 70%;
    }

    tbody th {
        font-weight: 400;        
    }

    ul {
        list-style: circle;
        padding: 0px 20px;
    }
}