@import 'components/constants';

.sideMenu {
    position: fixed;
    height: 100%;
    width: $sideMenuWidth;
    top: 100px;
    margin: 0;
    padding: 25px 0px;    
    background-color: #f1f1f1;    
    overflow: auto;
}