.tabheader {   
    :global(.nav-link) {
        &:global(.active) {
            font-style: italic;
            font-weight: bold;   
        }
    }

    li {
        cursor: pointer;
    }
}