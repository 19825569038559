.recordedGroups {
    width: 100%;
    overflow-x: auto;

    table {
        min-width: 750px;
    }

    tbody th {
        font-weight: 400;
    }
}

.flexContainer {
    display: flex;    
    align-items: center;

    .flexItemRight {
        margin-left: auto;
    }
}