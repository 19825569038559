.navMenu {    
    .topbar {
        position: fixed;
        width: 100%;
        min-height: 100px;
        top: 0;
        left:0;
        z-index: 100;
        background-color: rgb(248, 248, 248);
    }

    a.navbar-brand {
        white-space: normal;
        text-align: center;
        word-break: break-all;
    }
      
    .box-shadow {
        box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
    }

    .navLogoKpn {
        width: 150px;
        margin: 0px -10px;
    }

    .navLogoCyberTwice {
        width: 300px;
        margin: 0px 0px;
    }
}
