.fingerprintTable {
    width: 50%;
    overflow-x: auto;   
    
    table {
        width: 100%;
    }

    tbody th {
        font-weight: 400;        
    }

    ul {
        list-style: circle;
        padding: 0px 20px;
    }
}