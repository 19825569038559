.groupPermissions {
    width: 100%;
    overflow-x: auto;   
    
    table {
        min-width: 1000px;
    }

    tbody th {
        font-weight: 400;        
    }

    ul {
        list-style: circle;
        padding: 0px 20px;
    }
}

.flexContainer {
    display: flex;    
    align-items: center;

    .flexItemRight {
        margin-left: auto;
    }
}