.sideMenuItem {
    :global(.nav-item) {
        &:hover {
            background-color:rgb(218, 218, 218);
        }
    }

    .icon {
        position: relative;
        top: -2px;
        font-size: 1.2em;
        float: left;
        width: 38px;
    }

    .menuLink {        
        font-size: 1.2rem;
        padding-left: 16px;     
        
        .text {        
            color: rgb(80,80,80);
        }
    }  
    
    :global(.active) { /* The :global prevents name mangling which happens for all other class names */
        font-weight: 500;
        background-color: white;
    }
}