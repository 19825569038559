.home {
    .topImageContainer {
        position: relative;    
        overflow: hidden;    

        img {
            position: absolute;
            margin-top: 0;
            left: 0;
            top: 0;
            width:100%;
        }
    }

    .overlayHeader {
        position: absolute;
        top: 15%;
        left: 5%;

        p {
            color:white;
            font-size: 4.2vw;
            font-weight: 500;
        }
    }

    .overlayRevision {
        position: absolute;
        bottom: 0%;
        left: 1%;

        p {
            color:white;            
        }
    }   
}