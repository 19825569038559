.rangeSelect {
    width: 350px;
    margin-right: auto;
}

.warningMessage {
    color: red
}

.tenantFoundMessage {
    font-weight: bold;
    font-size: 110%;
}