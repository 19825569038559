.publicLayout {
    .logoKpn {
        width: 10%;
        margin: 10px 10px;
    }
    
    .logoCyberTwice {
        width: 20%;   
        top: 0;    
        margin: 30px 20px;
    } 
    
    .hidden {
        visibility: hidden;
    }
}