.userLogin {    
    .accountIcon {
        height: 42px;
        margin-right: 12px;
        margin-bottom: 3px;        
        border-radius: 50%;
    }

    .defaultIcon {
        background-color: lightgray;
        padding: 4px;
    }
}